.wrap {
  height: 100%;
  min-width: 800px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
}

.home {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 20;
  // width: 100px;
  // height: 100px;
  opacity: 0.5;
  & img {
    vertical-align: top;
    // width: 100%;
    // height: 100%;
  }
}

.nav {
  // margin-left: 100px;
  position: relative;
  border-bottom: 2px solid #ededed;
  background-color: #2284f5;

  height: 3px;
  margin: 0 10px;

  & ul {
    list-style: none;
    margin: 0;
    padding: 0;
    // display: flex;
    // flex-direction: row;
  }
  & ul li {
    background-color: #ededed;
    transform-origin: 0% 0%;
    position: absolute;
    transform: rotate() translate();
    left: 0;
    top: 0;
    // border-radius: 10px;
    &.on,
    &:hover {
      background-color: #c3c3c3;
    }
    & span {
      font-size: 0.7em;
      color: #5c4c4c;
    }
    & span::before {
      content: ' (';
    }
    & span::after {
      content: ')';
    }
    & span {
      font-size: 0.7em;
    }

    & a {
      white-space: wrap;
      word-wrap: normal;
      word-break: keep-all;
      padding: 2px 10px 2px 10px;
      display: block;
      color: #0061ff;
      text-decoration: none;
    }
  }
}
