.tools {
  margin: 0;
  padding: 0;  
}

.summ {
  & dl {
    margin-top: 12px;
    
  }

  & dt {
    font-weight: bold;
    font-size: 17px;
  }
  & dd {
    margin-top: 6px;
    margin-left: 10px;

  }
}
