.contents {
  margin: 0 auto;
  max-width: 1000px;
  padding: 0 10px 0 10px;
  h1{margin-top: 5px;}
  & img {
    margin: 7px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
    border: 1px solid white;
    width: calc(100% - 2px);
    max-width: 1000px;
    vertical-align: top;
  }

  & img.mobileImg {
    max-width: calc(50% - 20px);
    display: inline-block;
  }

  & section {
    margin: 0;
  }
}

.project_desc {
  & dl {
    margin-top: 12px;
  }

  & dt {
    font-weight: bold;
    font-size: 17px;
  }
  & dd {
    margin-top: 6px;
    margin-left: 10px;
  }
}
